.author-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    top: -35px;
    z-index: 3;
    margin-right: 25px;
}

.author-info .msg {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
}

a.essay-avatar {
    height: 60px;
    width: 60px;
    display: block;
    border-radius: 10px;
    background: #fff;
}

[data-theme=dark] a.essay-avatar {
    background: #ffffffad;
}

.eassy-name {
    margin-right: 10px;
    padding-top: 2px;
    color: #eee;
    font-weight: 600;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    display: -webkit-box;
    overflow: hidden;
}

.content.narrow-row {
    padding: 0 50px;
}

#bber {
    margin-top: -2rem;
}

#bber div.bber-content {
    position: relative;
    flex-direction: column;
}

#bber .bber-container-img {
    justify-content: flex-start;
}

#bber .bber-content-img,
#bber .bber-content-video {
    width: 32%;
    margin-right: 1%;
}

#bber .bber-content .datacont {
    font-size: 14px;
    font-weight: 400;
    color: var(--font-color);
}

#bber .bber-content-img img {
    width: 100%;
}

#bber .bber-bottom {
    display: inline !important;
}

#bber>section>ul>li>div .bber-info-from,
#bber>section>ul>li>div .bber-info-time {
    background: 0 0;
    padding: 0;
    height: 26px;
}

hr.essay-hr {
    margin: 25px auto;
    border: 1px solid var(--hr-border);
}

.bber-name,
.user-avatar {
    display: none;
}

.bber-reply {
    position: absolute;
    right: 15px;
    bottom: 0;
    height: 26px;
    line-height: 26px;
}

time.datatime {
    font-size: 13px;
}

.bber-info-link {
    position: absolute;
    right: 40px;
    bottom: 0;
    line-height: 26px;
}

.bber-info-address {
    color: #4e9df8;
    font-size: .7rem;
}

div#bber-tips {
    padding-bottom: 20px;
}

@media screen and (max-width:900px),
(max-height:580px) {
    body[data-type=essay] #body-wrap .layout #page {
        padding: 0 5px !important;
    }

    body[data-type=essay] div#post-comment {
        padding: 20px 15px !important;
    }

    body[data-type=essay] .layout {
        padding: 0 !important;
    }

    body[data-type=essay] .right {
        margin-left: 55px;
    }

    div#essay_page {
        border: var(--style-border);
        box-shadow: var(--anzhiyu-shadow-border);
        border-radius: 10px;
    }

    a.essay-avatar {
        height: 60px;
        width: 60px;
    }

    .author-content.author-content-item.essayPage.single {
        height: 16rem;
        border-radius: 10px;
    }

    .desc {
        font-size: 14px;
    }

    #bber {
        margin-top: -3rem !important;
        width: 95%;
        margin: auto;
    }

    #bber .timeline ul li.bber-item {
        width: 95%;
        border: none;
        border-radius: 0;
        padding: 0;
        background: 0 0;
        box-shadow: none;
    }

    #bber .timeline ul li.bber-item:hover {
        border: none;
    }

    .bber-name {
        font-size: 16px;
        font-weight: 800;
        display: block;
    }

    .user-avatar {
        width: 45px;
        height: 45px;
        border-radius: 10px;
        overflow: hidden;
        position: absolute;
        display: block;
    }
}